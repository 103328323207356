import { LD_JSON_RECIPE_CAROUSELS } from 'lib/brandFeatures';
import { getAllPackages } from 'lib/curationUtils';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';

/**
 *
 * @param {Curation} curation
 * @param {VerticalType} vertical
 * @returns {ItemList | null}
 */
export const buildRecipeCarouselFromFront = (curation, vertical) => {
  const recipeCarouselEnabled = getFeatureConfigForBrand(LD_JSON_RECIPE_CAROUSELS, vertical);
  if (!recipeCarouselEnabled) {
    return null;
  }
  const packages = getAllPackages(curation);
  const recipeItems = packages
    .map((pkg) => pkg.items)
    .filter(Boolean)
    .reduce((acc, curItem) => {
      const relatedItems = curItem
        .reduce((allRelatedItems, cur) => {
          const { related } = cur || {};
          return [...allRelatedItems, ...(related || [])];
        }, []);
      return [...acc, ...curItem, ...relatedItems];
    }, [])
    .filter((item) => item && item.type === 'recipe' && item.computedValues?.url);

  const urls = recipeItems
    .map((recipe) => recipe?.computedValues?.url ?? null)
    .filter(Boolean);

  if (urls.length < 2) { // Google requires 2 items for the structured data to be valid.
    return null;
  }

  return {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    itemListElement: urls.map((url, i) => ({
      '@type': 'ListItem',
      position: i + 1,
      url,
    })),
  };
};
