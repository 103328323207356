import getConfig from 'next/config';

import { useGateAccess } from 'lib/Hooks';
import { isShellArticle as isShell } from 'lib/article';
import { getUniqueAdditionalTermsReg } from './getUniqueAdditionalTermsReg';

const filterEmbeddedVideo = (item) => item.type === 'embeddedVideo' && item.video;

const getVideosFromArticle = (primaryMedia, body) => {
  if (!Array.isArray(body)) {
    return [];
  }

  const bodyVideos = body.filter(filterEmbeddedVideo);
  return (primaryMedia && filterEmbeddedVideo(primaryMedia))
    ? [primaryMedia, ...bodyVideos]
    : bodyVideos;
};

/**
 * Dataset
 * @param {Article} article
 * @param {VerticalType} vertical
 */
export function buildDataset(article, vertical) {
  const {
    body = [],
    ecommerceEnabled,
    nativeAd,
    source,
    primaryMedia,
    taxonomy,
    url,
  } = article;

  const {
    primarySubvertical,
    primarySection,
    primaryTopic,
    primaryLabel,
    additionalTerms,
  } = taxonomy;

  const terms = additionalTerms || [];

  const isShellArticle = isShell(article);
  const hasAccess = useGateAccess(article);

  return {
    '@context': {
      '@vocab': 'http://schema.org',
      pageType: {
        '@id': 'Text',
        '@type': '@id',
      },
      vertical: {
        '@id': 'Text',
        '@type': '@id',
      },
      subVertical: {
        '@id': 'Text',
        '@type': '@id',
      },
      section: {
        '@id': 'Text',
        '@type': '@id',
      },
      subSection: {
        '@id': 'Text',
        '@type': '@id',
      },
      label: {
        '@id': 'Text',
        '@type': '@id',
      },
      packageId: {
        '@id': 'Text',
        '@type': '@id',
      },
      sponsor: {
        '@id': 'Text',
        '@type': '@id',
      },
      ecommerceEnabled: {
        '@id': 'Text',
        '@type': '@id',
      },
      videoPlayerCount: {
        '@id': 'Text',
        '@type': '@id',
      },
      appVersion: {
        '@id': 'Text',
        '@type': '@id',
      },
      tags: {
        '@id': 'Text',
        '@type': '@id',
      },
      gatedContentEnabled: {
        '@id': 'Text',
        '@type': '@id',
      },
    },
    '@type': 'Dataset',
    name: 'additionalTaxonomy',
    description: 'This is additional taxonomy that helps us with analytics',
    url: url.primary,
    pageType: 'Article', // need lowercase later, and try to not be hardcoded
    vertical,
    subVertical: (primarySubvertical?.slug) || '',
    section: (primarySection?.slug) || '',
    subSection: (primaryTopic?.slug) || '',
    label: (primaryLabel?.slug) || '',
    packageId: '', // packages are for fronts?
    sponsor:
      nativeAd || isShellArticle
        ? source?.organization?.name
        : '',
    ecommerceEnabled,
    videoPlayerCount: getVideosFromArticle(primaryMedia, body).length,
    appVersion: getConfig()?.publicRuntimeConfig?.appVersion ?? '',
    tags: getUniqueAdditionalTermsReg(terms, /\w\/keyword\/\w?/),
    gatedContentEnabled: !hasAccess,
  };
}
