/**
 * Parse article and products to build pros/cons list
 * @param {list} list
 * @returns list of object structured for ldjson
 */
function buildList(list) {
  return list.map((item, i) => ({
    '@type': 'ListItem',
    position: i + 1,
    name: item,
  }));
}

/**
 * Parse article and products to build pros/cons list
 * @param {object} prosCons
 * @param {object} article
 * @returns ldjson structure for pros and cons review
 */

export const buildProsCons = (prosCons, article = {}) => {
  const review = {
    '@type': 'Review',
  };

  if (article?.authors?.[0]?.person?.name) {
    review.author = {
      '@type': 'Person',
      name: article?.authors?.[0]?.person?.name,
      url: article?.authors?.[0]?.person?.url?.primary || '',
    };
  }

  if (prosCons.pros.items && prosCons.pros.items.length > 0) {
    review.positiveNotes = {
      '@type': 'ItemList',
      itemListElement: buildList(prosCons.pros.items),
    };
  }

  if (prosCons.cons.items && prosCons.cons.items.length > 0) {
    review.negativeNotes = {
      '@type': 'ItemList',
      itemListElement: buildList(prosCons.cons.items),
    };
  }

  return review;
};
