/**
 * Parse article unibrow and primary headline to build breadcrumb list
 * @param {object} data (article)
 * @returns ldjson structure for breadcrumb
 */

export const buildBreadcrumbs = (data) => {
  const parentUrl = data.unibrow?.url?.primary;
  const parentName = data.unibrow?.text;
  const curPageUrl = data.url.primary;
  const curPageName = data.headline.seo;

  // check if breadcrumb exists
  if (!parentUrl || !parentName || parentUrl === curPageUrl) {
    return null;
  }

  const listItems = [
    {
      '@type': 'ListItem',
      position: 1,
      name: parentName,
      item: parentUrl,
    },
    {
      '@type': 'ListItem',
      position: 2,
      name: curPageName,
      item: curPageUrl,
    },
  ];

  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: listItems,
  };
};
