import getConfig from 'next/config';

/**
 * @param {?} author
 * @param {VerticalType} vertical
 */
export function buildAuthor(author, vertical) {
  const {
    url = { primary: '' },
    primarySubvertical = '',
    primarySection = '',
    primaryTopic = '',
    nativeAd = '',
    source = '',
  } = author;

  return {
    '@context': {
      '@vocab': 'http://schema.org',
      pageType: {
        '@id': 'Text',
        '@type': '@id',
      },
      vertical: {
        '@id': 'Text',
        '@type': '@id',
      },
      subVertical: {
        '@id': 'Text',
        '@type': '@id',
      },
      section: {
        '@id': 'Text',
        '@type': '@id',
      },
      subSection: {
        '@id': 'Text',
        '@type': '@id',
      },
      packageId: {
        '@id': 'Text',
        '@type': '@id',
      },
      sponsor: {
        '@id': 'Text',
        '@type': '@id',
      },
      appVersion: {
        '@id': 'Text',
        '@type': '@id',
      },
    },
    '@type': 'Dataset',
    name: 'additionalTaxonomy',
    description: 'This is additional taxonomy that helps us with analytics',
    url: url.primary,
    pageType: 'author',
    vertical,
    subVertical: (primarySubvertical?.slug) || '',
    section: (primarySection?.slug) || '',
    subSection: (primaryTopic?.slug) || '',
    packageId: '',
    sponsor: nativeAd ? source?.name : '', // not sure there is source for the author page
    appVersion: getConfig()?.publicRuntimeConfig?.appVersion ?? '',
  };
}
