/**
 * Dataset
 * @param {Article} article.body
 * @param {Faqs} faqs array of objects with questions and answers
 */

import { getFAQS } from 'lib/getFAQs';

export function buildFAQ(article) {
  const faqs = getFAQS(article) || [];
  const mainEntity = faqs.map((faqElement) => {
    const { question, answer: { html: answerHtml } } = faqElement;
    return {
      '@type': 'Question',
      name: question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: answerHtml,
      },
    };
  });

  if (!mainEntity.length) return {};

  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity,
  };
}
