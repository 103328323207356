import getISODate from 'lib/getISODate';
import { getPublisher, getCreator } from 'lib/headerMetadata';
import { getCloudinaryFlavoredImage } from 'lib/imageUtils';
import { shareImage } from 'lib/social';

import { cloudinaryFlavorLd } from './cloudinaryFlavorLd';

export const buildImageGallery = (slideshow, vertical) => {
  const {
    headline = { primary: '', seo: '' },
    url = { primary: '' },
    imageUrl = '',
    dateCreated = '',
    dateModified = '',
    primarySection = { displayName: '' },
    id = '',
    description = { primary: '' },
  } = slideshow;

  const LDJSONSlides = slideshow.slides.map((item) => {
    if (item) {
      const image = item.image || {};
      const slide = {
        '@type': 'ImageObject',
        url: getCloudinaryFlavoredImage(image.url.primary, cloudinaryFlavorLd),
        name: (image.headline?.primary) || '',
        caption: image.caption, // looks like captions have HTML in them?
      };
      return slide;
    }
    return '';
  });

  const ldjson = {
    '@context': {
      '@vocab': 'http://schema.org',
      articleId: {
        '@id': 'Text',
        '@type': '@id',
      },
    },
    '@type': 'imageGallery',
    headline: headline.primary,
    url: url.primary,
    thumbnailUrl: shareImage(imageUrl, vertical),
    creator: getCreator(vertical),
    alternativeHeadline: headline.seo,
    dateCreated: getISODate(dateCreated),
    datePublished: getISODate(dateCreated),
    dateModified: getISODate(dateModified),
    articleSection: primarySection.displayName,
    articleId: id,
    identifier: {
      '@type': 'PropertyValue',
      propertyID: 'uid',
      value: id,
    },
    image: LDJSONSlides,
    publisher: getPublisher(vertical),
    description: description.primary,
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': url.primary,
    },
  };

  return ldjson;
};
