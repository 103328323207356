const HTML_TAG_STRIP_REGEX = /(<([^>]+)>)/ig;

export const getTextFromParagraphMarkup = (data, field) => {
  const paragraphMarkup = (data?.[field] ?? [])
    .find((p) => p.type === 'markup' && p.element === 'p');

  if (paragraphMarkup && typeof paragraphMarkup.html === 'string') {
    return paragraphMarkup.html.replace(HTML_TAG_STRIP_REGEX, '');
  }

  return undefined;
};
