import { LD_JSON_RECIPE_CAROUSELS } from 'lib/brandFeatures';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';

/**
 * Checks if a Body Element is an embeddedRecipe and that there is a recipe with a canonical url
 * @param {BodyElement} item
 * @returns {Boolean}
 */
const filterEmbeddedRecipe = (item) => item.type === 'embeddedRecipe' && item.recipe?.url?.primary;

/**
 * Gets the EmbeddedRecipes from an Article's body
 * @param {Article} param0 Article to check for Embedded Recipes
 * @returns {EmbeddedRecipe[]}
 */
const getRecipesFromArticle = ({ body } = {}) => {
  if (!body || !Array.isArray(body)) {
    return [];
  }
  return body.filter(filterEmbeddedRecipe);
};

/**
 * Returns a ItemList structured data object for a list of recipes, conforming to Schema.org
 * @param {Article} article
 * @param {VerticalType} vertical
 * @returns { ItemList | null}
 */
export const buildRecipeCarouselFromArticle = (article, vertical) => {
  const recipeCarouselEnabled = getFeatureConfigForBrand(LD_JSON_RECIPE_CAROUSELS, vertical);
  if (!recipeCarouselEnabled) {
    return null;
  }
  const embeddedRecipes = getRecipesFromArticle(article);
  if (embeddedRecipes.length < 2) { // Google requires 2 items for the structured data to be valid.
    return null;
  }
  return {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    itemListElement: embeddedRecipes.map((embeddedRecipe, i) => ({
      '@type': 'ListItem',
      position: i + 1,
      url: embeddedRecipe.recipe.url.primary,
    })),
  };
};
