/**
 * Build closed caption media object
 * @param {string} url
 * @param {string} encodingFormat
 * @returns {Array}
 */
export const buildVideoMediaObject = (url, encodingFormat) => {
  if (!url || !encodingFormat) {
    return null;
  }
  return {
    '@type': 'MediaObject',
    encodingFormat,
    url,
    inLanguage: 'en',
  };
};
