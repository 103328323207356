import getISODate from 'lib/getISODate';
import { getPublisher } from 'lib/headerMetadata';
import { getCloudinaryFlavoredImage } from 'lib/imageUtils';
import { shareImage } from 'lib/social';
import { useGateAccess } from 'lib/Hooks';
import { NEWSARTICLE_LDJSON_DATES } from 'lib/brandFeatures';

import { buildAuthors } from './buildAuthors';
import { cloudinaryFlavorLd } from './cloudinaryFlavorLd';
import { getUniqueAdditionalTermsReg } from './getUniqueAdditionalTermsReg';

/**
 * NewsArticle
 * @param {Article} article
 * @param {VerticalType} vertical
 */
export function buildNewsArticle(article, vertical) {
  const {
    authors,
    experts,
    dateCreated,
    datePublished,
    description,
    headline,
    id,
    primaryImage,
    socialImage,
    taxonomy: {
      allTerms,
      primarySection,
      primaryVertical = {},
    },
    teaseImage,
    topics,
    url,
    content,
  } = article;

  const image = socialImage || teaseImage || primaryImage || {};
  const imageUrl = (image.url?.primary);

  const keywords = getUniqueAdditionalTermsReg(
    [primaryVertical, ...(allTerms || [])],
    /\w\/(vertical|category)\/\w?/,
  ) || primaryVertical?.name;

  const hasAccess = useGateAccess(article);

  const articleBody = content && Array.isArray(content.text) ? content.text.join(' ') : '';

  const authorsWithoutContributors = authors?.filter((author) => author.authorType === 'author');
  const contributorAuthors = authors?.filter((author) => author.authorType === 'contributor');
  const contributors = [
    ...(Array.isArray(contributorAuthors) ? contributorAuthors : []),
    ...(Array.isArray(experts) ? experts : []),
  ];

  const newsArticleDates = NEWSARTICLE_LDJSON_DATES[vertical] ? {
    dateCreated: getISODate(dateCreated),
    datePublished: getISODate(dateCreated),
    dateModified: getISODate(datePublished),
  } : {
    // datePublished maps to the last modified time
    // this is more accurate than the dateModified field for different article types
    dateModified: getISODate(datePublished),
    datePublished: getISODate(dateCreated),
  };

  const schema = {
    '@context': {
      '@vocab': 'http://schema.org',
      articleId: {
        '@id': 'Text',
        '@type': '@id',
      },
      topics: {
        '@id': 'Text',
        '@type': '@id',
      },
    },
    '@type': 'NewsArticle',
    headline: headline.seo || headline.primary,
    url: url.primary,
    thumbnailUrl: shareImage(imageUrl, vertical),
    creator: authors?.[0]?.name,
    alternativeHeadline: headline.primary,
    articleBody,
    ...newsArticleDates,
    articleSection: (primarySection?.slug) || 'news',
    articleId: id,
    identifier: {
      '@type': 'PropertyValue',
      propertyID: 'uid',
      value: id,
    },
    topics: (topics && topics.map((item) => item.slug).join(',')) || '',
    image: image.url ? {
      '@type': 'ImageObject',
      url: getCloudinaryFlavoredImage(image.url.primary, cloudinaryFlavorLd),
      caption: image.caption,
    } : '',
    author: buildAuthors({ authors: authorsWithoutContributors, vertical }),
    publisher: getPublisher(vertical),
    description: description.seo,
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': url.primary,
    },
    keywords,
  };

  if (contributors && contributors.length > 0) {
    schema.contributor = buildAuthors({
      authors: contributors.map(({ person }) => person), vertical,
    });
  }

  if (!hasAccess) {
    schema.isAccessibleForFree = 'False';
    schema.hasPart = {
      '@type': 'WebPageElement',
      isAccessibleForFree: 'False',
      cssSelector: '.article-body__content',
      value: articleBody,
    };
  }

  return schema;
}
