import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { RENDER_SHORT_BIO } from 'lib/brandFeatures';
import { buildOrganization } from './buildOrganization';
import { buildPerson } from './buildPerson';

/**
 * Generates an array of Author metadata based on a provided list. If no authors are provided, a
 * default NBC Universal author will be generated.
 * @param {Author[]} authors
 */
export const buildAuthors = ({ authors = [], vertical = '' }) => {
  if (!Array.isArray(authors) || !authors.length) {
    return [{
      ...buildOrganization(vertical),
    }];
  }
  const shortBioEnabled = getFeatureConfigForBrand(RENDER_SHORT_BIO, vertical);

  return authors.reduce((result, item) => {
    const { type, name, person } = item;
    const personName = person?.name ?? name;

    if (['NBC News', 'NBC Select', 'MSNBC', 'TODAY', 'Shop TODAY'].includes(personName)) {
      const orgNameToVerticalMap = {
        'NBC News': 'news',
        'NBC Select': 'select',
        MSNBC: 'msnbc',
        TODAY: 'today',
      };
      const orgVertical = orgNameToVerticalMap[personName] ?? vertical;
      const isShopToday = personName === 'Shop TODAY';
      result.push({
        ...buildOrganization(orgVertical, isShopToday),
      });
    } else if (type === 'embeddedPerson' && typeof person !== 'undefined') {
      // ex. Article object with embedded person object(s)
      result.push(buildPerson({
        person,
        useAuthorShortBio: shortBioEnabled,
        vertical,
      }));
    } else if (type === 'person') {
      // ex. Card with basic person object
      result.push(buildPerson({
        person: item,
        useAuthorShortBio: shortBioEnabled,
        vertical,
      }));
    } else if (typeof name !== 'undefined') {
      // undefined/unexpected
      result.push(buildPerson({
        person: { name },
        useAuthorShortBio: shortBioEnabled,
        vertical,
      }));
    }
    return result;
  }, []);
};
