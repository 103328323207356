import getISODate from 'lib/getISODate';
import { buildCollectionPage } from './buildCollectionPage';
import { buildPerson } from './buildPerson';
import { getSchemaTemplate } from './getSchemaTemplate';

/**
 * @param {?} person
 * @param {VerticalType} vertical
 */
export function buildProfilePage(person, vertical, publisherId = 'https://www.nbcnews.com/#publisher') {
  const author = buildPerson(
    {
      person,
      useAuthorShortBio: false,
      vertical,
    },
  );

  const schema = {
    ...getSchemaTemplate('ProfilePage'),
    dateCreated: getISODate(person.dateCreated),
    dateModified: getISODate(person.dateModified),
    mainEntity: author,
    hasPart: { ...buildCollectionPage(person, vertical, publisherId) },
  };

  return schema;
}
