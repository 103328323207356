import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { ENABLE_NEWS_MEDIA_ORG_SCHEMA_TYPE } from 'lib/brandFeatures';
import { getSchemaTemplate } from './getSchemaTemplate';
import { getPublisher } from '../headerMetadata';


/**
 * ItemList
 * @param {?[]} itemList
 */
function buildItemList(itemList) {
  const schema = {
    ...getSchemaTemplate('ItemList'),
    itemListElement: [],
    numberOfItems: itemList.length,
  };
  itemList.forEach((item, i) => {
    schema.itemListElement.push({
      ...getSchemaTemplate('ListItem'),
      url: item?.url?.primary,
      position: (i + 1),
    });
  });
  return schema;
}
/**
 * CollectionPage
 * @param {?} collectionObject
 * @param vertical
 * @param publisherId
 */
export function buildCollectionPage(collectionObject, vertical, publisherId = 'https://www.nbcnews.com/#publisher') {
  // eslint-disable-next-line max-len
  const useNewsOrgType = getFeatureConfigForBrand(ENABLE_NEWS_MEDIA_ORG_SCHEMA_TYPE, vertical);
  let schema = {
    ...getSchemaTemplate('CollectionPage'),
    inLanguage: 'en-US',
    copyrightHolder: {
      '@id': publisherId,
      ...(useNewsOrgType && { '@type': 'NewsMediaOrganization' }),
    },
    copyrightYear: (new Date()).getFullYear(),
    publisher: getPublisher(vertical),
    sourceOrganization: {
      '@id': publisherId,
      ...(useNewsOrgType && { '@type': 'NewsMediaOrganization' }),
    },
    description: undefined,
    name: undefined,
    url: undefined,
  };

  if (collectionObject?.type === 'person') {
    schema = {
      ...schema,
      // main entity, item list
      mainEntity: buildItemList(collectionObject?.content?.items),
      mainEntityOfPage: collectionObject?.url?.primary,
      name: collectionObject?.name,
      url: collectionObject?.url?.primary,
    };
  } else if (collectionObject?.jsonLdPageType === 'SectionFront') {
    schema = {
      ...schema,
      description: collectionObject?.description,
      name: collectionObject?.title,
      publisher: getPublisher(vertical),
      url: collectionObject?.url,
    };
  }

  return schema;
}
