import { getSchemaTemplate } from './getSchemaTemplate';

const verticalOrgMap = {
  news: {
    name: 'NBC News',
    url: 'https://www.nbcnews.com',
  },
  select: {
    name: 'NBC Select',
    url: 'https://www.nbcnews.com/select',
  },
  msnbc: {
    name: 'MSNBC',
    url: 'https://www.msnbc.com',
  },
  today: {
    name: 'TODAY',
    url: 'https://www.today.com',
  },
};

/**
 * NewsMediaOrganization
 * @param {string} vertical
 * @param {boolean} isShopToday
 */
export const buildOrganization = (vertical, isShopToday = false) => {
  if (isShopToday) {
    return ({
      '@context': 'https://schema.org/',
      '@type': 'NewsMediaOrganization',
      name: 'Shop TODAY',
      url: 'https://www.today.com/shop',
    });
  }

  const { name, url } = verticalOrgMap[vertical] ?? {};

  return name && url ? {
    ...getSchemaTemplate('NewsMediaOrganization'),
    name,
    url,
  } : {
    ...getSchemaTemplate('Organization'),
    name: 'NBC Universal',
    '@id': 'https://www.nbcuniversal.com/',
  };
};
