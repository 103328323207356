export const buildItemList = ({ items }) => {
  if (!items?.length) return null;

  const numberOfItems = items.length;
  return {
    '@context': 'https://schema.org/',
    '@type': 'ItemList',
    numberOfItems,
    itemListElement: [
      ...items,
    ],
  };
};
