/**
 * @param {VideoAsset[]} videoAssets
 */
export function getContentUrl(videoAssets) {
  if (!Array.isArray(videoAssets) || !videoAssets.length) {
    return null;
  }

  const filteredAssets = videoAssets.filter((asset) => asset);

  if (!filteredAssets.length) {
    return null;
  }

  try {
    // wrapping all of this in a try/catch, as new URL can throw an error if the arg isn't a valid
    // URL. and ultimately, if we can't find the vars we need for a content URL, we can just return
    // null

    const maxBitrate = Math.max(...filteredAssets.map(({ bitrate }) => bitrate));
    const { publicUrl } = filteredAssets.find(({ bitrate }) => bitrate === maxBitrate);

    const {
      origin,
      pathname,
      searchParams,
    } = new URL(publicUrl);

    searchParams.append('format', 'redirect');

    const contentUrl = `${origin}${pathname}?${searchParams.toString()}`;

    return contentUrl;
  } catch (e) {
    // no op
    return null;
  }
}
