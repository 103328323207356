import { getAllPackages } from 'lib/curationUtils';
import { buildListicleProduct } from 'lib/LDJson/buildListicleProduct';
import { buildItemList } from 'lib/LDJson/buildItemList';

/**
 *
 * @param {Curation} curation
 * @param {VerticalType} vertical
 * @returns {ItemList | null}
 */
export const buildProductBestsellersFromFront = (curation) => {
  const packages = getAllPackages(curation);
  const productPackages = packages
    .map((pkg) => pkg.items)
    ?.filter(Boolean)
    ?.reduce((acc, curItem) => {
      const relatedItems = curItem.reduce((allRelatedItems, cur) => {
        const { related } = cur || {};
        return [...allRelatedItems, ...(related || [])];
      }, []);
      return [...acc, ...curItem, ...relatedItems];
    }, [])
    ?.filter(
      (item) => item && item.type === 'product' && item.computedValues?.url,
    )
    ?.map((i) => {
      const { item } = i;
      const {
        description, name, offers, teaseImage,
      } = item;
      const productUrl = offers[0]?.externalUrl ?? '#';
      const imageUrl = teaseImage?.url?.primary ?? '#';
      return JSON.parse(
        buildListicleProduct({
          name,
          description,
          offers,
          productUrl,
          imageUrl,
        }),
      );
    }) ?? [];

  return buildItemList({ items: productPackages });
};
