import dayjs from 'dayjs';
import { getContentUrl } from 'lib/urlUtils';
import { buildVideoObjectLD } from './buildVideoObjectLD';

/**
 * Live Broadcast
 * Mirrors ld+json for YouTube NASA live video:
 * https://www.youtube.com/watch?v=EEIk7gwjgIM
 * @param {Object} obj
 * @param {string} obj.title
 * @param {string} obj.description
 * @param {string} obj.duration
 * @param {string} obj.thumbnailUrl
 * @param {string} obj.uploadDate
 * @param {string} obj.genre
 * @param {string} obj.author
 * @param {string} obj.startDate
 * @param {boolean} obj.includeMainEntityOfPage
 * @param {string} obj.url
 */
export function buildLiveVideoObjectLD({
  title,
  description,
  duration,
  thumbnailUrl,
  uploadDate,
  startDate,
  includeMainEntityOfPage,
  url,
  videoAssets,
  closedCaptioning,
  closedCaptioningContent,
}, isLiveVideo = true) {
  const contentUrl = getContentUrl(videoAssets);
  return buildVideoObjectLD(
    {
      name: title, // "NASA Live Stream - Earth From Space LIVE Feed | ISS tracker &  live chat"
      description,
      duration,
      thumbnailUrl, // "https://i.ytimg.com/vi/EEIk7gwjgIM/maxresdefault_live.jpg"
      contentUrl, // "http://link.theplatform.com/s/rksNhC/4WsiVwMcjH_r?MBR=TRUE&format"
      uploadDate, // "2019-08-31"
      startDate, // "2019-08-31T10:39:48+00:00"
      endDate: dayjs().add(1, 'day').startOf('hour').toISOString(),
      isLiveBroadcast: isLiveVideo,
      url,
      closedCaptioning,
      closedCaptioningContent,
    },
    {
      includePublicationData: isLiveVideo,
      includeMainEntityOfPage,
    },
  );
}
