import getISODate from 'lib/getISODate';
import { getPublisher } from 'lib/headerMetadata';
import { getCloudinaryFlavoredImage } from 'lib/imageUtils';
import { transformCardLinkToHashLink } from 'lib/transformCardLinkToHashLink';

import { cloudinaryFlavorLd } from './cloudinaryFlavorLd';
import { buildAuthors } from './buildAuthors';

/**
 * BlogPosting
 * @param {Article} blog
 * @param {string} vertical
 * @param {Article} [content]
 */
export function buildBlogPosting(blog, vertical, content = {}) {
  const {
    authors, content: {
      text,
    } = {}, date: {
      createdAt, modifiedAt,
    } = {}, description, headline = {}, url,
  } = blog;

  // grab the created date for static publish timestamp
  const datePublished = getISODate((blog?.dateCreated ?? undefined) || createdAt);
  // datePublished field is available when viewing the blog as a whole;
  // - maps to the published date the last card added in the blog
  // falls back to modifiedAt when looking at individual card view
  // - maps to when the individual card was last modified
  const dateModified = getISODate((blog?.datePublished ?? undefined) || modifiedAt);

  const postUrl = url?.primary ? transformCardLinkToHashLink(url.primary) : null;
  const post = {
    '@context': 'https://schema.org/',
    '@type': 'BlogPosting',
    publisher: getPublisher(vertical),
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': postUrl,
    },
    headline: headline.seo || headline.primary,
    url: postUrl,
    datePublished,
    dateModified,
    author: buildAuthors({ authors, vertical }),
  };

  const primaryImage = blog?.primaryImage?.url?.primary ?? null;
  const secondaryImage = content?.teaseImage?.url?.primary ?? null;
  const defaultImage = blog?.teaseImage?.url?.primary ?? null;
  const image = primaryImage || secondaryImage || defaultImage;
  const seoOrPrimaryDescription = description?.seo || description?.primary;
  // Image from blog or main content
  post.image = getCloudinaryFlavoredImage(image, cloudinaryFlavorLd);

  // ArticleBody from text content or card description
  if (text && Array.isArray(text)) {
    post.articleBody = text.join('\n\n');
  } else if (seoOrPrimaryDescription) {
    post.articleBody = seoOrPrimaryDescription;
  }
  if (seoOrPrimaryDescription && content.subType !== 'liveBlog') {
    post.description = seoOrPrimaryDescription;
  }

  return post;
}
