import getConfig from 'next/config';
import { useGateAccess } from 'lib/Hooks';

import { getUniqueAdditionalTermsReg } from './getUniqueAdditionalTermsReg';

/**
 * Recipe
 * @param {Recipe} recipe
 * @param {VerticalType} vertical
 */
export const buildRecipeDataset = (recipe, vertical) => {
  const {
    taxonomy,
    url = { primary: '' },
    nativeAd,
    source,
    ecommerceEnabled,
  } = recipe;

  const {
    primarySubvertical,
    primarySection,
    primaryTopic,
    primaryLabel,
    additionalTerms,
  } = taxonomy || {};

  const terms = additionalTerms || [];

  const hasAccess = useGateAccess(recipe);

  return {
    '@context': {
      '@vocab': 'http://schema.org',
      pageType: {
        '@id': 'Text',
        '@type': '@id',
      },
      vertical: {
        '@id': 'Text',
        '@type': '@id',
      },
      subVertical: {
        '@id': 'Text',
        '@type': '@id',
      },
      section: {
        '@id': 'Text',
        '@type': '@id',
      },
      subSection: {
        '@id': 'Text',
        '@type': '@id',
      },
      label: {
        '@id': 'Text',
        '@type': '@id',
      },
      packageId: {
        '@id': 'Text',
        '@type': '@id',
      },
      sponsor: {
        '@id': 'Text',
        '@type': '@id',
      },
      appVersion: {
        '@id': 'Text',
        '@type': '@id',
      },
      tags: {
        '@id': 'Text',
        '@type': '@id',
      },
      ecommerceEnabled: {
        '@id': 'Text',
        '@type': '@id',
      },
      gatedContentEnabled: {
        '@id': 'Text',
        '@type': '@id',
      },
    },
    '@type': 'Dataset',
    name: 'additionalTaxonomy',
    description: 'This is additional taxonomy that helps us with analytics',
    url: url.primary,
    pageType: 'recipe',
    vertical,
    subVertical: (primarySubvertical?.slug) || '',
    section: (primarySection?.slug) || '',
    subSection: (primaryTopic?.slug) || '',
    label: (primaryLabel?.slug) || '',
    packageId: '',
    sponsor: nativeAd ? source?.name : '',
    appVersion: getConfig()?.publicRuntimeConfig?.appVersion ?? '',
    tags: getUniqueAdditionalTermsReg(terms, /\w\/keyword\/\w?/),
    ecommerceEnabled,
    gatedContentEnabled: !hasAccess,
  };
};
