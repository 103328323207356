import { getCloudinaryFlavoredImage } from 'lib/imageUtils';
import { getProfileURL } from 'lib/social';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { ENABLE_NEW_AUTHOR_PAGE_SEO } from 'lib/brandFeatures';

import { cloudinaryFlavorLd } from './cloudinaryFlavorLd';
import { getSchemaTemplate } from './getSchemaTemplate';
import { getTextFromParagraphMarkup } from './getTextFromParagraphMarkup';

/**
 * Person
 * @param {object} person
 */
export const buildPerson = ({
  person = {},
  useAuthorShortBio = true,
  vertical = '',
}) => {
  const image = person?.primaryImage?.url?.primary ?? null;
  // Person schema
  const schema = {
    ...getSchemaTemplate('Person'),
    name: person?.name ?? null,
    image: getCloudinaryFlavoredImage(image, cloudinaryFlavorLd),
    jobTitle: person?.jobTitle?.[0] ?? null,
  };

  // description
  const shortBio = person?.shortBio ?? null;
  if (useAuthorShortBio && shortBio) {
    schema.description = shortBio;
  } else {
    const longBio = getTextFromParagraphMarkup(person, 'bio');
    if (longBio) {
      schema.description = longBio;
    }
  }

  if (getFeatureConfigForBrand(ENABLE_NEW_AUTHOR_PAGE_SEO, vertical)) {
    // knowsAbout
    const knowsAbout = getTextFromParagraphMarkup(person, 'educationAndExpertise');
    if (knowsAbout) {
      schema.knowsAbout = knowsAbout;
    }
    // disambiguatingDescription
    const experience = getTextFromParagraphMarkup(person, 'experience');
    if (experience) {
      schema.disambiguatingDescription = experience;
    }
    // awards
    const awards = getTextFromParagraphMarkup(person, 'awards');
    if (awards) {
      schema.award = awards;
    }
  }
  // url
  const url = person?.url?.primary ?? null;
  if (url) {
    schema.url = url;
  }
  // worksFor
  const work = person?.worksFor?.name;
  if (work) {
    schema.worksFor = [{
      ...getSchemaTemplate('Organization'),
      name: work,
    }];
  }
  // sameAs / socialMediaProfiles
  const profiles = person?.socialMediaProfiles ?? {};
  const showProfile = (key) => !!profiles[key] && key !== 'signal' && key !== 'whatsapp';
  const keys = Object.keys(profiles).filter((key) => showProfile(key));
  if (keys.length) {
    schema.sameAs = [];
    keys.forEach((key) => {
      schema.sameAs.push(getProfileURL(key, profiles[key]));
    });
  }

  return schema;
};
